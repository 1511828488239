<template>
  <div class="list-require-stock-selected-modal">
    <b-modal
      id="selected-require-stocks-modal"
      ref="selected-require-stocks-modal"
      hide-footer
      title="Phát hành vận chuyển"
      no-close-on-backdrop
      size="xl"
      @show="resetModal"
      @hidden="resetModal"
    >
      <b-overlay
        :show="isLoadingGetRequiredStockSlipListByIds"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-container fluid>
          <b-row>
            <b-col>
              <ListRequireStockSelectedModalTable :requireStockSlips="requiredStockSlipsWithValidateStatus" />
            </b-col>
          </b-row>

          <b-row class="mb-1 text-center modal-footer">
            <b-col class="d-flex justify-content-end">
              <b-button
                v-if="checkPermission(['PORTAL_FAST_REQUIRE_STOCK_ISSUE']) && !issueComplete"
                class="ml-2"
                style="width: 80px"
                size="sm"
                variant="primary"
                @click="onIssueToFastPortal"
                :disabled="isIssuing"
              >
                <b-spinner
                  v-if="isIssuing"
                  small
                  label="Đang xử lí..."
                ></b-spinner>
                <span v-else><strong>Phát hành</strong></span>
              </b-button>

              <b-button
                class="ml-2"
                style="width: 80px"
                size="sm"
                variant="outline-secondary"
                @click="hideModal"
              >
                <span>Hủy bỏ</span>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { VAT_INVOICE_PUSH } from '@/utils/enum';
import {
  makeToastFaile,
  makeToastSuccess,
  convertPrice,
  calculateDiscountAmount,
} from '@/utils/common';
import ApiService from '@/core/services/api.service';
import { EventBus } from '@/core/services/event-bus';
import { getRequiredStockByIdMixin } from '@/view/mixins';
import { showConfirmNotify } from '@/utils/sweet-alert2';
import Bluebird from 'bluebird';
const { checkPermission } = require('@/utils/saveDataToLocal');
const ListRequireStockSelectedModalTable = () =>
  import(
    /* webpackPrefetch: true */ '@/view/pages/stocks/ListRequireStockSelectedModalTable.vue'
  );

const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%',
};

const STATUS_VALIDATE = {
  INVALID: 0,
  NOT_ALLOW_INCLUDE_LIKE_NEW_PRODUCT: 1,
  VALID: 2,
  READY: 3,
  LOADING: 4,
};

export default {
  name: 'ListRequireStockSelectedModal',
  components: { ListRequireStockSelectedModalTable },
  mixins: [getRequiredStockByIdMixin],
  data() {
    return {
      mode: null,
      showOverlay: false,
      title: '',
      vatBill: null,
      isCreating: false,
      disabled: false,
      fields: [
        {
          key: 'index',
          label: 'STT',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '1%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'sourceStoreId',
          label: 'Kho',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '3%' },
        },
        {
          key: 'code',
          label: 'Mã phiếu',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'relateStockCode',
          label: 'Từ phiếu CK',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'statusValidate',
          label: 'Trạng thái',
          thStyle: {
            ...thStyleCommon,
            width: '3%',
          },
          tdClass: 'align-middle text-center',
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '1%' },
        },
      ],
      fieldsProduct: [
        {
          key: 'index',
          label: 'STT',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '1%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'quantity',
          label: 'SL duyệt',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'unitPrice',
          label: 'Đơn giá',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'totalAmount',
          label: 'Thành tiền',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '5%',
          },
          tdClass: 'text-center',
        },
      ],
      vatInvoice: {},
      componentView: '',
      requiredStockSlipsWithValidateStatus: [],
      isIssuing: false,
      issueComplete: false,
    };
  },

  computed: {
    isModeAdd() {
      return this.mode && this.mode === 'add';
    },
  },
  created() {},

  mounted() {
    EventBus.$on('selected-require-stocks-modal', this.showModal);
  },

  methods: {
    makeToastFaile,
    makeToastSuccess,
    calculateDiscountAmount,
    convertPrice,
    checkPermission,
    async onIssueToFastPortal() {
      const content = {
        title: `Phát hành phiếu vận chuyển`,
        html: `Bạn có chắc muốn phát hành phiếu yêu cầu chuyển kho này không ?  <p class="font-weight-bold font-italic text-warning">Lưu ý: sau khi phát hành phiếu vận chuyển thì phiếu XNK sẽ không thể hủy, xóa, sửa</p>`,
        actionName: 'Xác nhận',
        confirmButtonColor: '#3699FF',
      };
      const result = await showConfirmNotify(content);
      if (!result.value) return;

      this.issueStockToFastPortal();
    },
    async issueStockToFastPortal() {
      if (this.isIssuing) return;

      const clonedRequiredStockSlips = [
        ...this.requiredStockSlipsWithValidateStatus,
      ];

      const validRequiredStockSlips = clonedRequiredStockSlips.filter(
        (requiredStockSlip) =>
          requiredStockSlip.statusValidate === STATUS_VALIDATE.READY,
      );

      if (!validRequiredStockSlips || !validRequiredStockSlips.length) {
        makeToastFaile('Không có phiếu nào hợp lệ để phát hành');
        return;
      }

      this.isIssuing = !this.isIssuing;

      await Bluebird.each(
        validRequiredStockSlips,
        async (requiredStockSlip) => {
          const index = this.requiredStockSlipsWithValidateStatus.findIndex(
            (e) => e.id === requiredStockSlip.id,
          );
          this.requiredStockSlipsWithValidateStatus[index].statusValidate =
            STATUS_VALIDATE.LOADING;

          try {
            await ApiService.post(
              `stock-slips/${requiredStockSlip.id}/issue-transfer`,
            );
            requiredStockSlip.statusValidate = STATUS_VALIDATE.VALID;
            this.requiredStockSlipsWithValidateStatus[
              index
            ].statusValidateName = 'Phát hành thành công';
          } catch (err) {
            const message = err.response
              ? err.response.data.message
              : 'Lỗi mạng';

            requiredStockSlip.statusValidate = STATUS_VALIDATE.INVALID;
            this.requiredStockSlipsWithValidateStatus[
              index
            ].statusValidateName = message || 'Lỗi không xác định';
          }
        },
      );

      this.isIssuing = false;
      this.issueComplete = true;
    },
    viewTransferStock: function (item) {
      const { href } = this.$router.resolve({
        name: 'update-transfer-stock',
        query: { id: item.relateStock },
      });
      window.open(href, '_blank');
    },
    resetModal() {
      this.requiredStockSlips = null;
      this.issueComplete = false;
      this.isIssuing = false;
      this.isLoadingGetRequiredStockSlipListByIds = false;
      this.errorGetRequiredStockSlipList = null;
    },
    makePushStatus({ isChecked }) {
      return isChecked
        ? VAT_INVOICE_PUSH.APPROVED
        : VAT_INVOICE_PUSH.UN_APPROVED;
    },
    mappingPushStatusBillVatDetails() {
      const details = [...this.vatBill.details];

      const newDetails = details
        .map((product) => ({
          ...product,
          id: this.isModeAdd ? null : product.id,
          push: this.makePushStatus(product),
        }))
        .filter((product) => product.push === VAT_INVOICE_PUSH.APPROVED);

      return newDetails;
    },
    async onUpsertBillVat() {
      if (this.isCreating) return;
      this.isCreating = true;

      const convertedDetails = this.mappingPushStatusBillVatDetails();

      if (!convertedDetails || !convertedDetails.length) {
        makeToastFaile('Chọn ít nhât 1 sản phẩm để tạo hóa đơn đỏ.');
        this.isCreating = false;
        return;
      }

      const billVat = Object.assign({}, this.vatBill, this.vatInvoice, {
        details: convertedDetails,
      });

      try {
        const url = 'vat-bills';
        const method = this.isModeAdd ? 'post' : 'put';
        const res = await ApiService[method](url, billVat);
        this.$emit('handlerFetchListBill');
        makeToastSuccess(res.data.message);
        setTimeout(() => {
          this.hideModal();
        }, 500);
      } catch (error) {
        if (error.response) {
          makeToastFaile(
            error.response.data
              ? error.response.data.message
              : 'Có vấn đề xảy ra hãy thử lại',
          );
        } else {
          makeToastFaile(error.message || 'Có vấn đề xảy ra hãy thử lại');
        }
      } finally {
        setTimeout(() => {
          this.isCreating = false;
        }, 500);
      }
    },
    async getNewBillVatByBillNumber(billNumber) {
      if (!billNumber) {
        return;
      }

      const url = 'vat-bills';
      const method = 'get';
      try {
        const res = await ApiService[method](`${url}/${billNumber}`);

        const billVat = res.data.data;

        const details = billVat.details || [];
        const convetedDetails = details.map((product) => ({
          ...product,
          isChecked: false,
        }));
        return Object.assign({}, billVat, { details: convetedDetails });
      } catch (error) {
        if (error.response) {
          makeToastFaile(
            error.response.data
              ? error.response.data.message
              : 'Có vấn đề xảy ra hãy thử lại',
          );
        } else {
          makeToastFaile(error.message || 'Có vấn đề xảy ra hãy thử lại');
        }
      }
    },
    hideModal() {
      this.requiredStockSlips = null;
      this.issueComplete = false;
      this.isIssuing = false;
      this.$emit('resetSelectedRequiredStocks');
      this.$refs['selected-require-stocks-modal'].hide();
    },
    async showModal({ selectedRequiredStocks }) {
      this.$bvModal.show('selected-require-stocks-modal');

      await this.getRequiredStockSlipListByIds(
        selectedRequiredStocks.map(
          (selectedRequiredStock) => selectedRequiredStock.id,
        ),
      );

      this.requiredStockSlipsWithValidateStatus = (
        this.requiredStockSlips || []
      ).map((requiredStockSlip) => {
        const LIKE_NEW_PRODUCT_PROPERTY_VALUE_ID = 1332162;
        const validProduct = requiredStockSlip.listDetail.every(
          (item) => item.size !== LIKE_NEW_PRODUCT_PROPERTY_VALUE_ID,
        );
        const statusValidate = validProduct
          ? STATUS_VALIDATE.READY
          : STATUS_VALIDATE.NOT_ALLOW_INCLUDE_LIKE_NEW_PRODUCT;
        return {
          ...requiredStockSlip,
          statusValidate: statusValidate,
          errors: [],
        };
      });
    },
  },

  beforeDestroy() {
    EventBus.$off('selected-require-stocks-modal');
  },
};
</script>

<style lang="scss">
#selected-require-stocks-modal {
  .modal-dialog.modal-xl {
    max-width: 90vw;
  }
  .modal-header {
    position: sticky;
    top: 0;
    background-color: inherit;
    z-index: 1055;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }
}
</style>
